@keyframes sidebar-menu-spawn {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0);
  }
}

.sidebar-menu {
  animation: sidebar-menu-spawn 0.2s ease;
  hr {
    @apply border-gray-200 dark:border-gray-700;
  }
}

.sidebar-menu__root--visible {
  .sidebar-menu {
    transform: translateX(0);
  }
}

.sidebar-menu .wtf-panel {
  box-shadow: none !important;
  margin: 0;
}
